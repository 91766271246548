.root {
    display: grid;
    width: var(--width);
    height: var(--height);
    place-content: center;

    svg {
        width: 100%;
        height: 100%;
    }
}
